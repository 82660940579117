import "./App.css";
import TextFieldSizes from "./components/inputField";

function App() {
  return (
    <div className="App">
      <TextFieldSizes />
    </div>
  );
}

export default App;
