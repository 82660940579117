import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Grid, Paper } from "@mui/material";
import maleShirtImage from "../assets/Shirt-body-Male.png";
import femaleShirtImg from "../assets/Shirt-body-female.jpg";
import underwearImage from "../assets/Underwear-body.jpg";
import underwear from "../assets/Underwear.jpg";
import shirt from "../assets/Shirt.jpg";
import SizeLabel from "./sizeLabel";
import SelectType from "./select";
import axios from "axios";
function App() {
  const [values, setValues] = useState({
    chest: 0,
    length: 0,
    sleeveLength: 0,
    sleeveWidth: 0,
    hip: 0,
    waist: 0,
    gender: "male",
    type: "shirt",
    unit: "cm",
  });

  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    axios
      .get("http://208.109.243.36:80/size/getSizes", {
        params: { type: values.type, gender: values.gender },
      })
      .then((response) => {
        console.log(response.data);
        setSizes(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [values.type, values.gender]);
  const changeType = (value) => {
    setValues({ ...values, type: value });
  };

  const changeGender = (value) => {
    setValues({ ...values, gender: value });
  };

  const changeMeasurements = (value) => {
    setValues({ ...values, unit: value });
  };
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5" align="center" gutterBottom>
        Find Your Best Fit
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <SelectType
          type={values.type}
          handleChange={changeType}
          onChange={(e) => {
            changeType(e.target.value);
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 4,
          flexDirection: { xs: "column", sm: "row", md: "row" },
        }}
      >
        <Button variant="standard" sx={{ mx: 1 }}>
          Gender
        </Button>
        <Button
          variant={values.gender == "male" ? "contained" : "outlined"}
          sx={{ mx: 1 }}
          onClick={(e) => {
            changeGender("male");
          }}
        >
          M
        </Button>
        <Button
          variant={values.gender == "female" ? "contained" : "outlined"}
          sx={{ mx: 1 }}
          onClick={(e) => {
            changeGender("female");
          }}
        >
          F
        </Button>
        <Button variant="standard" sx={{ mx: 1 }}>
          Measurement
        </Button>
        <Button
          variant={values.unit == "cm" ? "contained" : "outlined"}
          sx={{ mx: 1 }}
          onClick={(e) => {
            changeMeasurements("cm");
          }}
        >
          CM
        </Button>
        <Button
          sx={{ mx: 1 }}
          variant={values.unit == "inch" ? "contained" : "outlined"}
          onClick={(e) => {
            changeMeasurements("inch");
          }}
        >
          IN
        </Button>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h7" align="center" gutterBottom>
              Favorite {values.type} measurements
            </Typography>
            <img
              src={values.type === "shirt" ? shirt : underwear}
              alt="Shirt Diagram"
              style={{ width: "100%" }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Box sx={{ textAlign: "right" }}>
                <Typography>Chest (A)</Typography>
                <Typography>Length (B)</Typography>
                <Typography>Sleeve length</Typography>
                <Typography>Circumference</Typography>
              </Box>
              <Box>
                <Typography>45 Inch</Typography>
                <Typography>45 Inch</Typography>
                <Typography>45 Inch</Typography>
                <Typography>45 Inch</Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    background: "#DBF3D9",
                    fontSize: "13px",
                    borderRadius: "50%",
                    margin: "2px",
                    lineHeight: "22px",
                  }}
                >
                  +40 Accuracy
                </Typography>
                <Typography
                  sx={{
                    background: "#DBF3D9",
                    fontSize: "13px",
                    margin: "2px",
                    borderRadius: "50%",
                    lineHeight: "22px",
                  }}
                >
                  +40 Accuracy
                </Typography>
                <Typography
                  sx={{
                    background: "#DBF3D9",
                    fontSize: "13px",
                    borderRadius: "50%",
                    margin: "2px",
                    lineHeight: "22px",
                  }}
                >
                  +40 Accuracy
                </Typography>
                <Typography
                  sx={{
                    background: "#DBF3D9",
                    fontSize: "13px",
                    borderRadius: "50%",
                    margin: "2px",
                    lineHeight: "22px",
                  }}
                >
                  +40 Accuracy
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h7" align="center" gutterBottom>
              Body measurements
            </Typography>
            <img
              src={
                values.type === "shirt"
                  ? values.gender === "male"
                    ? maleShirtImage
                    : femaleShirtImg
                  : underwearImage
              }
              alt="Body Diagram"
              style={{ width: "100%" }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Box sx={{ textAlign: "right" }}>
                <Typography>Chest (A)</Typography>
                <Typography>Length (B)</Typography>
                <Typography>Sleeve length</Typography>
                <Typography>Circumference</Typography>
              </Box>
              <Box>
                <Typography>45 Inch</Typography>
                <Typography>45 Inch</Typography>
                <Typography>45 Inch</Typography>
                <Typography>45 Inch</Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    background: "#DBF3D9",
                    fontSize: "13px",
                    borderRadius: "50%",
                    margin: "2px",
                    lineHeight: "22px",
                  }}
                >
                  +40 Accuracy
                </Typography>
                <Typography
                  sx={{
                    background: "#DBF3D9",
                    fontSize: "13px",
                    margin: "2px",
                    borderRadius: "50%",
                    lineHeight: "22px",
                  }}
                >
                  +40 Accuracy
                </Typography>
                <Typography
                  sx={{
                    background: "#DBF3D9",
                    fontSize: "13px",
                    margin: "2px",
                    borderRadius: "50%",
                    lineHeight: "22px",
                  }}
                >
                  +40 Accuracy
                </Typography>
                <Typography
                  sx={{
                    background: "#DBF3D9",
                    fontSize: "13px",
                    margin: "2px",
                    borderRadius: "50%",
                    lineHeight: "22px",
                  }}
                >
                  +40 Accuracy
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h6" align="center" gutterBottom>
              Your ideal shirt size
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {sizes.map((size, index) => (
                <SizeLabel
                  key={index}
                  size={size}
                  type={values.type}
                  unit={values.unit}
                />
              ))}
            </Box>
          </Paper>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Button variant="outlined">Save your data</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
