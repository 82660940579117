import React from "react";
import { Box, Typography, Divider } from "@mui/material";

const SizeLabel = ({ size, type, unit }) => {
  return (
    <Box
      sx={{
        width: 60,
        borderRadius: 2,
        overflow: "hidden",
        border: "2px solid green",
        textAlign: "center",
        color: "green",
        fontFamily: "Arial, sans-serif",
        marginY: "10px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "green",
          color: "white",
          padding: "10px 0",
        }}
      >
        <Typography variant="h6">{size.symbol.toUpperCase()}</Typography>
      </Box>
      <Box sx={{ padding: "10px 0" }}>
        {type === "shirt" ? (
          <>
            {" "}
            <Typography component="div">Chest</Typography>
            <Typography variant="body1">
              {unit == "inch"
                ? parseFloat((size.chest / 2.54).toFixed(2))
                : size.chest}
            </Typography>
            <Divider sx={{ marginY: 1, borderColor: "green" }} />
            <Typography component="div">Length</Typography>
            <Typography variant="body1">
              {unit == "inch"
                ? parseFloat((size.length / 2.54).toFixed(2))
                : size.length}
            </Typography>
          </>
        ) : (
          <>
            <Typography component="div">Waist</Typography>
            <Typography variant="body1">
              {unit == "inch"
                ? parseFloat((size.waist / 2.54).toFixed(2))
                : size.waist}
            </Typography>
            <Divider sx={{ marginY: 1, borderColor: "green" }} />
            <Typography component="div">Hips</Typography>
            <Typography variant="body1">
              {unit == "inch"
                ? parseFloat((size.hips / 2.54).toFixed(2))
                : size.hips}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SizeLabel;
